import * as React from "react";
import "./index.css";
import appStoreIcon from "../../assets/img/app-store-icon.png";
import playStoreIcon from "../../assets/img/play-store-icon.png";

type DownloadAppBannerProps = {
  className: string;
};

class DownloadAppBanner extends React.Component<DownloadAppBannerProps> {
  public static defaultProps = {
    className: "",
  };

  render() {
    const { className } = this.props;
    return (
      <div className={className + " wa-download-app-banner"}>
        <a className='download-app-image' target='_blank' rel='noopener noreferrer' href='https://apps.apple.com/gb/app/fatmas-kitchen/id1529743711'>
          <img alt='Fatmas Kitchen App' src={appStoreIcon} />
        </a>
        <a className='download-app-image' target='_blank' rel='noopener noreferrer' href='https://play.google.com/store/apps/details?id=com.mobideli.restaurant.fatmaskitchen'>
          <img alt='Fatmas Kitchen App' src={playStoreIcon} />
        </a>
      </div>
    );
  }
}

export default DownloadAppBanner;
